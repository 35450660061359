meta[property="og:image"] {
  max-width: 600px;
  height: auto;
}

.input-field {
  background-color: #f6f6f6;
  border: 1px solid #eeecec;
  width: 100%;
  border-radius: 5px;
  color: #414141;
}

.input-field:focus {
  background-color: white;
  outline: 2px solid transparent;
  border: 1px solid #439fc6;
  outline-offset: 2px;
}
.input-disabled {
  background-color: #a2a1a1;
  border: 1px solid #eeecec;
  width: 100%;
  border-radius: 5px;
  color: #ffffff;
}
.desc-btn-nursery {
  padding: 0.4rem;
  border-radius: 0.8rem;
  color: #066993;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  white-space: pre-wrap;
}
.desc-text-nursery {
  position: absolute;
  display: none;
  max-width: 200px;
  padding: 10px;
  border-radius: 0.3rem;
  z-index: 10;
  background-color: white;
  color: #414141;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.desc-btn-nursery:hover {
  background-color: #cde6f0;

  color: #066993;
}

.desc-btn-nursery:hover .desc-icon-nursery {
  color: #066993;
}

.desc-btn-nursery:hover .desc-text-nursery {
  display: inline;
}

.main-btn {
  background-color: #066993;
  color: white;
  border-radius: 35px;
}

.applied-filter-item {
  border-radius: 9999px;
  border: 1px solid #eeecec;
  cursor: pointer;
  color: #979797;
  background-color: white;
  width: fit-content;
  height: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-right: 0.5rem;
}

.tooltipP {
  position: relative;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  color: black;
  font-size: 8px;
  right: 10%;
  width: fit-content;
}

.tooltipP:hover .tooltip {
  visibility: visible;
}

.tree-number {
  cursor: pointer;
  border-radius: 0.5rem;
  width: fit-content;
  padding: 0.4rem;
  position: relative;
}

.info-icon {
  cursor: pointer;
  position: relative;
  margin-left: 8px;
}

.info-text {
  background-color: white;
  border-radius: 0.375rem;
  padding: 1rem;
  z-index: 10;
  width: 350px;
  font-size: 14px;
  display: none;
  position: absolute;
  color: black;
  left: 1%;
}

.info-icon:hover .info-text {
  display: block;
}

.tree-number:hover {
  color: #066993;
  background-color: #cde6f0;
}

.tree-info {
  background-color: white;
  border-radius: 0.375rem;
  padding: 1rem;
  z-index: 10;
  width: 12rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: none;
  position: absolute;
  text-align: left;
  color: #414141;
}

.tree-number:hover .tree-info {
  display: block;
}

.incident-info {
  background-color: white;
  border-radius: 0.375rem;
  padding-top: 7px;
  padding-bottom: 7px;
  z-index: 10;
  width: 170px;
  font-size: 12px;
  line-height: 1.25rem;
  display: none;
  position: absolute;
  text-align: left;
  color: #414141;
}

.tree-number:hover .incident-info {
  display: block;
}

.applied-filter-item:hover {
  background-color: #258bde1f;
  border-color: #439fc6;
  color: #439fc6;
}

.number-field-icons {
  position: absolute;
  --fa-primary-color: #979797;
  --fa-secondary-color: #c7c7c7;
  border-radius: 50px;
  display: flex;
  cursor: pointer;
}

.desc-btn {
  padding: 0.6rem;
  border-radius: 0.8rem;
  color: #414141;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  white-space: pre-wrap;
}

.desc-text {
  position: absolute;
  display: none;
  width: 400px;
  max-width: 500px;
  height: fit-content;
  padding: 20px;
  border-radius: 0.3rem;
  z-index: 10;
  background-color: white;
  color: #414141;
  overflow-wrap: break-word;
}

.desc-btn:hover {
  color: #066993;
  background-color: #cde6f0;
}

.desc-btn:hover .desc-text {
  display: inline;
}
.age-btn {
  border-radius: 0.8rem;
  color: #d2202a;
  opacity: 1;
  position: relative;
  cursor: pointer;
}
.age-text {
  position: absolute;
  display: none;
  width: 250px;
  height: fit-content;
  left: -85px;
  top: -85px;
  padding: 10px;
  border-radius: 0.3rem;
  z-index: 10;
  background-color: white;
  color: #414141;
  overflow-wrap: break-word;
}

.age-btn:hover .age-text {
  display: inline;
}

.number-field-icons:hover {
  color: #439fc6;
  background-color: rgb(239, 191, 7, 0.2);
}

.main-btn-disable {
  background-color: #9c9c9c;
  color: white;
  border-radius: 1.5rem;
}

.main-btn:hover {
  background-color: #0884b8;
}
.white-btn {
  color: #0884b8;
  border-radius: 1.5rem;
}
.white-btn:hover {
  background-color: #0884b8;
  color: white;
}
.parent-container {
  height: 100%;
  width: 100%;
  padding-left: 72px;
  padding-right: 72px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #f9fafb;
}

@media (max-width: 768px) {
  .parent-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.error-text {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.25rem;
  margin-top: 0.5rem;
  color: rgb(220 38 38 / 1);
}

.login-bg {
  background-image: url(../../public/img/Inshindo_login_image.jpg);
}

.p-tooltip {
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  width: fit-content;
  padding: 0.5rem;
}

.p-tooltip:hover {
  color: #066993;
  background-color: #cde6f0;
}

.p-tooltip .p-table {
  display: none;
  position: absolute;
  background-color: white;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1rem;
  width: 14rem;
  right: -120px;
  z-index: 10;
  text-align: left;
  color: #414141;
}

.p-tooltip:hover .p-table {
  display: block;
}

.desc-tooltip {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
}

.desc-tooltip p {
  display: none;
  position: absolute;
  max-width: 50px;
  height: fit-content;
  overflow-wrap: break-word;
  background-color: white;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border-radius: 0.375rem;
  padding: 10px;
  z-index: 10;
  font-size: 14px;
  left: 30px;
  top: 30px;
}

.desc-tooltip:hover p {
  display: block;
}

.hr-lines {
  position: relative;
  margin-top: 1.75rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 6px;
}

.hr-lines:after {
  content: " ";
  height: 1px;
  width: 82%;
  background: #eeecec;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.hr-lines-responsible {
  position: relative;
  margin-top: 1.75rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 6px;
}

.hr-lines-responsible:after {
  content: " ";
  height: 1px;
  width: 80%;
  background: #eeecec;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.name-number {
  position: relative;
  width: 31px;
  height: 31px;
  border-radius: 9999px;
  background-color: #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.name-number .name-content {
  background-color: white;
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
  font-size: 12px;
  width: 157px;
  height: fit-content;
}

.name-number:hover .name-content {
  display: block;
}

.grid-desc {
  overflow: hidden;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.name-overflow {
  overflow: hidden;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.avatar {
  background-color: #f6f6f6;
}

.avatar:hover {
  background: linear-gradient(0deg, gray, #f6f6f6 70%);
}

.timeline-container {
  margin-top: 30px;
  margin-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
}

.slider-container .swiper-button-next,
.slider-container .swiper-button-prev {
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 50%;
  background-color: #f2f2f2;
  width: 40px;
  height: 40px;
}

.slider-container:hover .swiper-button-next,
.slider-container:hover .swiper-button-prev {
  opacity: 1;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.status-btn {
  padding: 5px;
  border-radius: 0.8rem;
  color: #066993;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  white-space: pre-wrap;
}

.status-text {
  position: absolute;
  display: none;
  width: 400px;
  max-width: 500px;
  height: fit-content;
  padding: 20px;
  border-radius: 0.3rem;
  z-index: 10;
  background-color: white;
  color: #414141;
  right: -150px;
  top: 45px;
  overflow-wrap: break-word;
}

.status-btn:hover {
  background: #cde6f0;
}

.status-btn:hover .status-text {
  display: block;
}
.line-text {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 6px;
  justify-content: space-between;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: #eeecec;
  margin-left: 15px;
}

.line-text p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}
