.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #0884B8;
}
.swiper-button-next:after {
  color: #0884B8;
  font-size: 15px !important;
}
.swiper-button-prev:after {
  color: #0884B8;
  font-size: 15px !important;
}
